var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          attrs: {
            model: _vm.formData,
            "label-position": "right",
            "label-width": "80px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "店铺名:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.name,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "name", $$v)
                  },
                  expression: "formData.name"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "logo:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.logo,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "logo", $$v)
                  },
                  expression: "formData.logo"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "上级ID:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.parentId,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "parentId", _vm._n($$v))
                  },
                  expression: "formData.parentId"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "店铺类型:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.shopType,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "shopType", _vm._n($$v))
                  },
                  expression: "formData.shopType"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "核销码:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.verifyCode,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "verifyCode", $$v)
                  },
                  expression: "formData.verifyCode"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("保存")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.back } },
                [_vm._v("返回")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }